.avatarDisplay{
    display:flex;
    justify-content: flex-end;
}

.title {
    font-family:'Avenir';
    font-size:18px;
    margin-top:25px;
}

.message {
    font-family:'Avenir';
    font-size:18px;
    padding-top:30px;
    padding-left:15px;
    padding-right:15px;
}

.submessage {
    font-family:'Avenir';
    font-size:14px;
    padding-top:20px;
    padding-left:15px;
    padding-right:15px;
}

.marketing{
    font-family:'Avenir';
    font-size:14px;
    margin-top:28px;
    margin-bottom:50px;
    padding-left:15px;
    padding-right:15px;
}

.mainPage {
    padding-top:0px;
}

.instructions {
    font-family:'Avenir';
    font-size:14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.topLogo {
    width:50%;
    padding-left:60px;
    padding-top:25px;
}

.topLogoSplash{
    padding-top:150px;
    width:41px;
}

.separatorImage{
    width:90%;
}

.gridClass{
    margin-top:5px;
    margin-bottom:100px;
}

.avatarPhone{
    font-size:1.rem;
}

.button-bar{
    position: fixed;
    bottom: 0px;
    left:0px;
    padding-top:20px;
    padding-bottom:20px;
    background-color: rgba(0.9,0.9,0.9,0.1);    
}

.button {
    height : 38px;
    width : 90%;
    flex-direction : row;
    align-self : center;
    align-items : center;
    justify-content : center;
    border-radius: 50px;
    color: #fff;
    background-color: #1AB2C3;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border:none;
}