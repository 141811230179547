.topLogo{
    width:50%;
    padding-left:60px;
    padding-top:25px;    
}

.mainPage{
    padding-top:0px;
    width:100%;
}

.intro{
    font-family: Avenir;
}

.textName{
    font-family: Avenir;
    padding-top:20px;
    padding-bottom:10px;
}

.textPhone{
    font-family: Avenir;
    padding-top:10px;
    padding-bottom:10px;
}

.button{
    height : 38px;
    width : 90%;
    flex-direction : row;
    align-self : center;
    align-items : center;
    justify-content : center;
    border-radius: 50px;
    color: #fff;
    background-color: #1AB2C3;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border:none;    
}